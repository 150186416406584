//
//  Prefix the variables!:
//  $c- for colors
//  $t- for typographic variables (font-sizes, line-heights, ...)
//

//// grid

$g-cols: 24;
$g-gutter: 2.5rem;

//// typography

$t-base-font-size: 1.7rem;
$t-base-line-height: 1.2;
$t-space-line: $t-base-font-size * $t-base-line-height;

// fonts

$f-fa-free: "Font Awesome 5 Free";
$f-fa-brands: "Font Awesome 5 Brands";

$f-source-sans-pro: "Source Sans Pro", sans-serif;

$f-1: $f-source-sans-pro;

//// colors

// basic
$c-black: #000;
$c-white: #fff;

// vendor
$c-vimeo: #1ab7ea;
$c-youtube: #f00;
$c-box: #E3000B;

// brand (special colors of the customer)
$c-blue: #004e9d;
$c-gold: #c1ab62;
$c-gray-light: #e7e7e7;
$c-gray: #848484;

// order
$c-1: $c-blue;
$c-2: $c-black;
$c-3: $c-white;
$c-4: $c-gray-light;
$c-5: $c-gold;
$c-6: $c-gray;

// nested
$c-text: $c-2;
$c-link: $c-1;
$c-link-hover: $c-2;
$c-selection: rgba($c-1, 0.5);

// form colors
$c-input-bg: transparent;
$c-input-text: $c-text;

// error
$c-error-text: $c-1;

// $c-error-bg: lighten($c-1, 90%)
$c-error-border: $c-1;

//// animations
$a-duration: 0.3s;
$a-function: ease;

//// layout

$l-pad: 2.5rem;

@if variable-exists(g-gutter) {
  $l-pad: $g-gutter;
}

$l-input-padding-y: 0.5rem;
$l-input-padding-x: 0.8rem;

$l-input-padding: $l-input-padding-y $l-input-padding-x;

$l-button-padding-y: $l-input-padding-y;
$l-button-padding-x: $g-gutter / 2;

$l-button-padding: $l-button-padding-y $l-button-padding-x;

// navbar

// $navbar-break: lg;
// $navbar-bg: $c-1;
// $navbar-header:;
// $navbar-body:;
// $navbar-toggle-bar: $c-2;
// $navbar-toggle-bg: transparent;
// $navbar-toggle-hover-bar: $c-1;
// $navbar-toggle-hover-bg: transparent;
// $navbar-link: $c-2;
// $navbar-link-bg:;
// $navbar-link-hover: $c-3;
// $navbar-link-hover-bg: $c-1;
// $navbar-link-active: $navbar-link-hover;
// $navbar-link-active-bg: $navbar-link-hover-bg;
// $navbar-lvl2-icons: "\f0da\a0";
