.quote {
  &__content {
    font-size: $t-base-font-size + 0.2rem;
    font-style: italic;

    &::before {
      content: "„";
    }

    &::after {
      content: "“";
    }
  }

  &__footer {
    font-weight: 700;
  }
}
