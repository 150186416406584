.accordion-item {
  $self: &;

  margin-bottom: $t-space-line;

  &__header {
    cursor: pointer;
    transition: color $a-duration $a-function;

    ::selection {
      background: none;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__toggle {
    display: flex;
    align-items: baseline;

    @include states("&") {
      text-decoration: none;
    }

    &::before {
      @include fa-free-solid("\f101");

      display: block;
      flex: 0 0 auto;
      transition: transform $a-duration $a-function;
      will-change: transform;

      #{$self}--expanded & {
        transform: rotate(90deg);
      }
    }
  }

  &__body {
    display: none;
    border-bottom: 0.1rem solid transparent;

    &__inner {
      @include rm-last-margin;

      padding-top: $t-space-line;
    }
  }
}
