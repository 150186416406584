.form {
  $self: &;

  $label-width-mobile: 33%;
  $label-width-desktop: 33%;
  $input-width-mobile: 100% - $label-width-mobile;
  $input-width-desktop: 100% - $label-width-desktop;

  margin-bottom: gutter();

  &__legend, &__group {
    margin-bottom: gutter(0.5);
  }

  &__group {
    $group: &;

    &:last-child {
      margin-bottom: 0;
    }

    &--hidden-label {
      #{$self}__label {
        visibility: hidden;
      }
    }

    &--row {
      @include media-breakpoint-up(sm) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      #{$self} {
        &__label {
          @include media-breakpoint-up(sm) {
            width: $label-width-mobile;
            padding-top: $l-input-padding-y;
            padding-right: $l-input-padding-x;
            padding-bottom: $l-input-padding-y;
            margin-bottom: 0;
            text-align: right;
          }

          @include media-breakpoint-up(lg) {
            width: $label-width-desktop;
          }
        }

        &__controls {
          @include media-breakpoint-up(sm) {
            width: $input-width-mobile;
          }

          @include media-breakpoint-up(lg) {
            width: $input-width-desktop;
          }
        }
      }

      &#{$group}--without-label {
        justify-content: flex-end;
      }
    }
  }

  &__label {
    display: block;
    width: 100%;
    margin-bottom: gutter(0.25);

    &--required:after {
      content: "*";
    }
  }

  &__controls {
    width: 100%;
  }

  &__control {
    @include input;

    display: block;
    width: 100%;
  }

  &__select {
    position: relative;

    &__control {
      background: none;
      appearance: none;
    }
  }

  .parsley-errors-list {
    $indent: 0.9rem;

    display: none;

    &.filled {
      display: block;
      width: 100%;
      padding: $l-input-padding-y $l-input-padding-x $l-input-padding-y ($l-input-padding-x + $indent);
      margin: 0.4rem 0 0;
      list-style: none;

      @if variable-exists(c-error-text) {
        color: $c-error-text;
      }

      @if variable-exists(c-error-bg) {
        background-color: $c-error-bg;
      }

      @if variable-exists(c-error-border) {
        border: 0.1rem dashed $c-error-border;
      }
    }

    li {
      text-indent: -$indent;
    }
  }
}
