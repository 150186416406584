.site-nav {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: gutter();

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    font-weight: 700;
    color: $c-2;

    @include states("&") {
      text-decoration: none;
    }

    @include states("&--active") {
      color: $c-1;
    }
  }
}
