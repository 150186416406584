.legal-nav {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    display: flex;

    &::after {
      display: block;
      margin-right: 0.3rem;
      margin-left: 0.3rem;
      color: $c-6;
      content: "\7c";
    }

    &:last-child::after {
      content: none;
    }
  }

  &__link {
    color: $c-6;

    @include states {
      color: $c-1;
    }
  }
}
