// global

html {
  background-color: $c-white;
}

body {
  font-family: $f-1;
  font-size: $t-base-font-size;
  line-height: $t-base-line-height;
  color: $c-text;
}

button, input, optgroup, select, textarea {
  font-family: $f-1;
  line-height: $t-base-line-height;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  margin-bottom: $t-space-line;
  font-weight: 600;
  color: $c-1;
}

h1, .h1 {
  font-size: 3.3rem;
}

h2, .h2 {
  font-size: 2.7rem;
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-size: $t-base-font-size;
}

blockquote, p, table, dl, ul, ol {
  margin: 0 0 $t-space-line;
}

audio, canvas, progress, video {
  margin: 0 0 $t-space-line;
}

a {
  color: $c-link;
  transition: color $a-duration $a-function;

  @include states("&") {
    text-decoration: underline;
  }

  @include states {
    color: $c-link-hover;
  }
}

strong, b {
  font-weight: 600;
}

.list {
  margin-bottom: $t-space-line;

  ul {
    $indent: $g-gutter;
    $content: "\f105";

    margin-block: 0;

    &, ul {
      padding-left: 0;
      list-style: none;
    }

    ul {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }

    li {
      padding-left: $indent;
      text-indent: -$indent;

      &::before {
        @include fa-free-solid($content);

        display: inline-block;
        width: $indent;
        color: $c-1;
        text-align: center;
        text-indent: 0;
      }
    }
  }
}

// classes

.background-gray {
  background-color: $c-4;
}

.header {
  *:not(:last-child):not(hr) {
    margin-bottom: 0;
  }
}

// layout

.page {
  display: flex;
  flex-direction: column;

  &--home {
    @include media-breakpoint-up(lg) {
      height: 100vh;
    }
  }
}

.site-wrap {
  flex: 1 0 auto;

  @include media-breakpoint-up(lg) {
    flex-shrink: 1;
  }

  .page--home & {
    display: flex;
    flex-direction: column;
  }
}

.site-section {
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  // flex-shrink: 0;
  padding: 2.5rem;

  &--top {
    @include media-breakpoint-up(lg) {
      flex-basis: 60%;
    }
  }

  &--content {
    @include media-breakpoint-up(lg) {
      flex-basis: 40%;
    }
  }

  .container-fluid {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .container--no-gutters {
    padding-right: 0;
    padding-left: 0;
  }

  .row {
    flex: 1 0 auto;
  }
}

.site-header {
  padding-top: gutter();
  padding-bottom: gutter();
}

.logo {
  transition: none;

  &, &__img {
    display: block;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}

.site-footer {
  flex: 0 0 auto;
  width: 100%;
  padding-top: gutter();
  padding-bottom: gutter();
}



