// Edit these value in the variables.scss

$grid-columns: $g-cols;
$grid-gutter-width: $g-gutter;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1248px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);
