// Based on bootstrap-grid.scss

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

// mixins
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../node_modules/bootstrap/scss/mixins/deprecate";
@import "../../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../../node_modules/bootstrap/scss/mixins/grid";
@import "../../../node_modules/bootstrap/scss/mixins/clearfix";
@import "../../../node_modules/bootstrap/scss/mixins/float";
@import "../../../node_modules/bootstrap/scss/mixins/hover";
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";
@import "../../../node_modules/bootstrap/scss/mixins/text-emphasis";
@import "../../../node_modules/bootstrap/scss/mixins/text-hide";
@import "../../../node_modules/bootstrap/scss/mixins/visibility";

// grid
@import "../../../node_modules/bootstrap/scss/grid";

// utilities
@import "../../../node_modules/bootstrap/scss/utilities/align";
@import "../../../node_modules/bootstrap/scss/utilities/clearfix";
@import "../../../node_modules/bootstrap/scss/utilities/display";
@import "../../../node_modules/bootstrap/scss/utilities/embed";
@import "../../../node_modules/bootstrap/scss/utilities/flex";
@import "../../../node_modules/bootstrap/scss/utilities/float";
@import "../../../node_modules/bootstrap/scss/utilities/position";
@import "../../../node_modules/bootstrap/scss/utilities/sizing";
@import "../../../node_modules/bootstrap/scss/utilities/spacing";
@import "../../../node_modules/bootstrap/scss/utilities/text";
@import "../../../node_modules/bootstrap/scss/utilities/visibility";
