@mixin rm-last-margin {
  > *:last-child {
    margin-bottom: 0;
  }
}

@mixin states($selector: null) {
  @if ($selector != null) {
    &:active, &:focus, &:hover, #{$selector} {
      @content;
    }
  } @else {
    &:active, &:focus, &:hover {
      @content;
    }
  }
}

@mixin resize-btn ($factor) {
  padding: ($l-button-padding-y * $factor) ($l-button-padding-x * $factor);
  font-size: percentage($factor);
}

@mixin btn {
  display: inline-block;
  padding: $l-button-padding;
  font-weight: 600;
  cursor: pointer;
  border: 0.2rem solid transparent;
  transition: all $a-duration $a-function;

  @include states("&") {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

@mixin btn-primary {
  @include states {
    color: $c-3;
    background-color: $c-1;
  }

  &, &:disabled {
    color: $c-1;
    background-color: $c-3;
    border-color: $c-1;
  }
}

@mixin more {
  $self: &;

  text-align: right;

  &__link {
    text-decoration: none;

    &::after {
      font-family: $f-fontawesome;
      content: "\a0\f152";
    }

    @include states {
      #{$self}__label {
        text-decoration: none;
      }
    }
  }

  &__label {
    text-decoration: underline;
  }
}

@mixin input {
  padding: $l-input-padding;
  line-height: $t-base-line-height;
  color: $c-input-text;
  background-color: $c-input-bg;
  border: 0.1rem solid $c-1;
  border-radius: 0;
  transition: box-shadow $a-duration $a-function;

  &:focus {
    outline: none;
    box-shadow: 0 0 0.4rem $c-1;
  }
}

// fontawesome

@mixin fa {
  font-style: normal;
  line-height: 1;
  text-rendering: auto;
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
}

@mixin fa-free-solid($icon) {
  @include fa;

  font-family: $f-fa-free;
  font-weight: 900;
  content: $icon;
}

@mixin fa-free-regular($icon) {
  @include fa;

  font-family: $f-fa-free;
  content: $icon;
}

@mixin fa-brands($icon) {
  @include fa;

  font-family: $f-fa-brands;
  content: $icon;
}
