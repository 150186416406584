.teaserbox {
  $self: &;

  position: relative;

  &,
  &__link {
    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  &__link {
    flex: 1 1 auto;

    @include states("&") {
      text-decoration: none;
    }
  }

  &__media {
    position: relative;
    flex: 1 1 auto;

    &::before {
      display: block;
      padding-top: percentage(9 / 16);
      content: "";

      @include media-breakpoint-up(lg) {
        content: none;
      }
    }

    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &__element {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  &__overlay {
    padding: gutter(0.5);
    background-color: $c-1;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0;
      background: none;
    }

    &__section {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &--upper {
        @include media-breakpoint-up(sm) {
          flex: 1 0 auto;
        }
      }

      &--lower {
        @include media-breakpoint-up(sm) {
          flex: 0 0 33.33%;
          background-color: rgba($c-1, 0.8);
        }
      }
    }
  }

  &__logo {
    width: 100%;
    margin-bottom: gutter(0.5);

    @include media-breakpoint-up(sm) {
      width: 66.66%;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 50%;
    }

    &__img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    width: 100%;
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding: gutter(0.5);
    }
  }

  &__title {
    font-size: 2.3rem;
    color: $c-3;
  }

  &__more {
    margin-bottom: 0;
  }

  .col-flex & {
    flex: 1 0 auto;
    height: 100vh;
  }

  &--black {
    #{$self} {
      &__overlay {
        background-color: $c-2;

        @include media-breakpoint-up(sm) {
          background: none;
        }

        &__section--lower {
          @include media-breakpoint-up(sm) {
            background-color: rgba($c-2, 0.4);
          }
        }
      }
    }
  }

  &--gold {
    #{$self} {
      &__overlay {
        background-color: $c-5;

        @include media-breakpoint-up(sm) {
          background: none;
        }

        &__section--lower {
          @include media-breakpoint-up(sm) {
            background-color: rgba($c-5, 0.8);
          }
        }
      }
    }
  }

  &--transparent {
    #{$self} {
      &__overlay {
        background-color: $c-2;

        @include media-breakpoint-up(sm) {
          background: none;
        }

        &__section--lower {
          @include media-breakpoint-up(sm) {
            background-color: transparent;
          }
        }
      }
    }
  }

  &--large {
    #{$self} {
      &__title {
        font-size: 3.3rem;
      }

      &__overlay {
        &__section--lower {
          @include media-breakpoint-up(sm) {
            flex: 0 0 25%;
          }
        }
      }
    }
  }
}
