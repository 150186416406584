// helpers

.large {
  font-size: $t-base-font-size + 0.2rem;
}

.small {
  font-size: $t-base-font-size - 0.2rem;
}

.btn {
  @include btn;

  &-link {
    padding: 0;
    color: $c-link;
    background: none;
    border: none;

    @include states {
      color: $c-link-hover;
      background: none;
    }
  }

  &-primary {
    @include btn-primary;
  }

  &-lg {
    @include resize-btn(1.2);
  }

  &-sm {
    @include resize-btn(0.8);
  }
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $t-space-line;

  > .btn {
    margin-right: $g-gutter / 2;
  }
}

.col-flex {
  display: flex;
  flex-direction: column;
}

.no-line {
  border-color: transparent;
}

.img-block {
  display: block;
  margin-bottom: $t-space-line;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.rounded-circle {
  border-radius: 50%;
}

.table-responsive {
  width: 100%;
  min-height: 0.01%;

  @include media-breakpoint-down(md) {
    margin-bottom: $t-space-line;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    .table {
      margin-bottom: 0;
    }
  }
}

.table-striped {
  tbody tr:nth-child(odd) {
    background-color: rgba($c-text, 0.1);
  }
}
