.razor {
  &--video {
    &-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      .razor--youtube, .razor--vimeo, .razor--vimeo--color {
        font-size: 3rem;
        opacity: 0.75;
        transition: all $a-duration $a-function;

        @include media-breakpoint-up(md) {
          font-size: 4rem;
        }
      }

      @include states {
        .razor--youtube, .razor--vimeo, .razor--vimeo--color {
          opacity: 1;
        }

        .razor--youtube {
          color: $c-youtube;
        }

        .razor--vimeo {
          color: $c-vimeo;
        }
      }
    }

    &-free {
      position: relative;

      iframe {
        border: none;
      }
    }
  }
}
