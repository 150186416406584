// box-sizing

*, ::after, ::before {
  box-sizing: border-box;
}

// selection

*::selection {
  background: $c-selection;
}

img::selection {
  background: none;
}

// basic elements

html, body {
  position: relative;
  width: 100%;
  // overflow: hidden;
}

html {
  height: 100%;
  overflow-y: auto;
  font-size: 62.5%;
}

body {
  min-height: 100%;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, button {
  -webkit-tap-highlight-color: rgba($c-black, 0);
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

a {
  &[href^=tel] {
    @include states("&") {
      color: inherit;
      text-decoration: none;
    }
  }
}

button {
  cursor: pointer;
}

audio, canvas, progress, video {
  display: block;
}

audio, table {
  width: 100%;
}

figure {
  margin: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-top-style: solid;
}

iframe {
  display: block;
}

input:not([type="checkbox"]):not([type="radio"]), textarea {
  -webkit-appearance: none;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

// slick

.slick-slide {
  &:focus {
    outline: none;
  }
}

// wow

.wow {
  &.bounceIn,
  &.bounceInDown,
  &.bounceInLeft,
  &.bounceInRight,
  &.bounceInUp,
  &.fadeIn,
  &.fadeInDown,
  &.fadeInDownBig,
  &.fadeInLeft,
  &.fadeInLeftBig,
  &.fadeInRight,
  &.fadeInRightBig,
  &.fadeInUp,
  &.fadeInUpBig,
  &.flipInX,
  &.flipInY,
  &.lightSpeedIn,
  &.rotateIn,
  &.rotateInDownLeft,
  &.rotateInDownRight,
  &.rotateInUpLeft,
  &.rotateInUpRight,
  &.jackInTheBox,
  &.rollIn,
  &.zoomIn,
  &.zoomInDown,
  &.zoomInLeft,
  &.zoomInRight,
  &.zoomInUp,
  &.slideInDown,
  &.slideInLeft,
  &.slideInRight,
  &.slideInUp {
    visibility: hidden;
  }
}
