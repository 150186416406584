$bourbon: (
  "global-font-file-formats": ("woff2", "woff")
);

// source sans pro

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-200"
// ) {
//   font-style: normal;
//   font-weight: 200;
// }

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-200italic"
// ) {
//   font-style: italic;
//   font-weight: 200;
// }

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-300"
// ) {
//   font-style: normal;
//   font-weight: 300;
// }

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-300italic"
// ) {
//   font-style: italic;
//   font-weight: 300;
// }

@include font-face(
  "Source Sans Pro",
  "../fonts/source-sans-pro/source-sans-pro-v13-latin-regular"
) {
  font-style: normal;
  font-weight: 400;
}

@include font-face(
  "Source Sans Pro",
  "../fonts/source-sans-pro/source-sans-pro-v13-latin-italic"
) {
  font-style: italic;
  font-weight: 400;
}

@include font-face(
  "Source Sans Pro",
  "../fonts/source-sans-pro/source-sans-pro-v13-latin-600"
) {
  font-style: normal;
  font-weight: 600;
}

@include font-face(
  "Source Sans Pro",
  "../fonts/source-sans-pro/source-sans-pro-v13-latin-600italic"
) {
  font-style: italic;
  font-weight: 600;
}

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-700"
// ) {
//   font-style: normal;
//   font-weight: 700;
// }

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-700italic"
// ) {
//   font-style: italic;
//   font-weight: 700;
// }

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-900"
// ) {
//   font-style: normal;
//   font-weight: 900;
// }

// @include font-face(
//   "Source Sans Pro",
//   "../fonts/source-sans-pro/source-sans-pro-v13-latin-900italic"
// ) {
//   font-style: italic;
//   font-weight: 900;
// }
