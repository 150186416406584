.news-archive {
  margin-bottom: $t-space-line;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item:before {
      content: none;
    }
  }

  &__item {
    &--year {
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }

      &__label {
        font-weight: 700;
      }
    }

    // &--month, &--back {
    //   &:before {
    //     font-family: $f-fontawesome;
    //     content: "\f0da\a0";
    //   }
    // }
  }

  &__link {
    &--active {
      color: $c-text;
      text-decoration: none;
    }
  }
}

.news-detail {
  &__teaser {
    font-weight: 700;
  }

  &__back {
    margin-bottom: 0;
  }

  &__comments {
    margin-top: $t-space-line;
  }

  &__content {
    @include clearfix;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    &__item {
      margin-right: 0.8rem;
      margin-bottom: $t-space-line;
      margin-left: 0.8rem;
    }
  }

  &__share {
    display: flex;
    flex-wrap: wrap;
    margin-top: $t-space-line;
    margin-bottom: $t-space-line;

    &__button {
      $size: 3.2rem;

      display: flex;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;
      margin-right: 0.4rem;
      font-size: 2rem;
      background-color: $c-1;
      transition: color $a-duration $a-function, background $a-duration $a-function;

      &:last-child {
        margin-right: 0;
      }

      @include states("&") {
        color: $c-white;
        text-decoration: none;
      }

      @include states {
        background-color: $c-2;
      }
    }
  }
}

.news-image, .news-video {
  margin-bottom: $t-space-line;

  &__caption {
    margin-top: 0.4rem;
  }
}

.news-image {
  &__link, &__img {
    display: block;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}

.news-article {
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 0.1rem solid $c-1;

  &:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
  }

  &__row {
    @include make-row();
  }

  &__col {
    @include make-col-ready();
    @include make-col(24);

    &--media {
      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }
    }

    &--content {
      @include media-breakpoint-up(sm) {
        @include make-col(16);
      }
    }
  }

  &__date {
    display: block;
  }

  &__title {
    &__link {
      color: $c-link-hover;

      @include states("&") {
        text-decoration: none;
      }

      @include states {
        color: $c-link;
      }
    }
  }

  &__media {
    &-link {
      display: block;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__more {
    margin-bottom: 0;
  }
}

.news-paginator {
  margin-top: $t-space-line;
  text-align: center;

  &__legend {
    margin-bottom: $t-space-line / 2;
    line-height: 1;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    margin-right: 0.2rem;
    margin-left: 0.2rem;

    &--prev {
      margin-left: 0;
    }

    &--next {
      margin-right: 0;
    }

    &--inactive {
      visibility: hidden;
    }
  }

  &__link {
    $item-width: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: $item-width;
    height: $item-width;
    font-weight: 700;
    line-height: 1;
    color: $c-2;
    background-color: $c-1;
    transition: background-color $a-duration $a-function, color $a-duration $a-function;

    @include states("&") {
      text-decoration: none;
    }

    @include states("&--current") {
      color: $c-1;
      background-color: $c-2;
    }
  }
}
